import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/login/Login';
import LeftNavBar from "./components/nav/LeftNavBar";
import RightPanel from "./components/rightpanel/RightPanel";
import Dashboard from "./sections/dashboard/Dashboard";
import Accounts from "./sections/accounts/Accounts";  
import Templates from "./sections/templates/Templates";
import API from "./sections/api/API";
import Config from "./sections/config/Config";
import { GoogleOAuthProvider } from '@react-oauth/google';

import initialProjectsdata from './init/project.json';
import auth from './init/auth.json';
import functions from './init/functions.json';
import networks from './init/networks.json';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [functionOptions, setFunctionOptions] = useState(functions);
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [curract, setCurract] = useState('...');
  const [initialProjects , setInitialProjects] = useState(initialProjectsdata);
  const [deletebtnshow, setDeleteBtnShow] = useState(0);
  const [isLoading, setIsLoading]= useState(false);
  const [loadComplete, setLoadComplete]= useState(true);
  const [panelIsVisible, setPanelIsVisible] = useState(true);
  const [isNavVisible, setIsNavVisible] = useState(true);
  

  const handleNavItemClick = (item) => {
    setActiveItem(item);

  };
  const handleLogout = (status) => {
    // Perform authentication logic here
    // For simplicity, we'll assume the login is always successful

    setIsLoading(true); 
    setIsLoggedIn(false);
    setCurract("Logging out...");
    setTimeout(() => {
      setIsLoading(false); 
      setCurract("...");
    }, 1500);

  };
  const handleLogin = (status) => {
    // Perform authentication logic here
    // For simplicity, we'll assume the login is always successful

    setIsLoading(true); 
    setCurract("Logging in...");
    setTimeout(() => {
      setIsLoading(false); 
      setCurract("...");
      setIsLoggedIn(true);
    
    }, 1500);

  };

  const deployTemplate = (templateData) => {
    // Implement your deployTemplate logic here
    templateData.githubRepo=".."; templateData.id=initialProjects.length+2;

    setInitialProjects([...initialProjects,templateData]);
    talknojutsu(["Generating Contract Files...","Generating Auth Service worker scripts...","Creating new project folder to Github...","Deploying service worker to Cloudflare...","Template successfully generated and deployed."]);

  };

  const talknojutsu = (cstatus) => {

    setIsLoading(true);

    var count =0;
      const updatemsg = (cstatus,count) => {
        setTimeout(() => {
          setCurract(cstatus[count]);
          if(count==cstatus.length-1) { setLoadComplete(false);  }
          if(count==cstatus.length) { setIsLoading(false);  setLoadComplete(true); }else{

          count++;
          updatemsg(cstatus,count);
        }
        }, 2000);
      };
    
    
      updatemsg(cstatus,0);

  };
  
  return (
    <GoogleOAuthProvider clientId="664954214187-tv7ieppgf2r3trq3nq64dnoni98qaiah.apps.googleusercontent.com">
    <div className="App">
    {isLoading && 
      <div class="load-bg">
           <div className={`loading-container ${loadComplete ? '' : 'complete'}`}>
       <div class="loading-circle"></div>
       <div class="loading-circle"></div>
       <div class="loading-circle"></div>
       <div class="loading-circle"></div>
       <div class="loading-text"> 
       <h3>Please wait... </h3>
       </div>
     </div>  
     <h3><sub>{curract}</sub></h3>
     </div>
    
       }


    
      {isLoggedIn ? (  <div><LeftNavBar activeItem={activeItem} const isNavVisible={isNavVisible} setIsNavVisible={setIsNavVisible} handleNavItemClick={handleNavItemClick} />
      <div className={`${panelIsVisible ? 'reduced' : 'fullview'} ${isNavVisible ? 'shownav' : 'hidenav'}`} > 
     

       {activeItem === 'Dashboard' && <div>
       <Dashboard  activeItem={activeItem} setActiveItem={setActiveItem} />
       </div>}
   
     {activeItem === 'Templates' && <div>
       <Templates initialProjects={initialProjects} setInitialProjects={setInitialProjects} />
       </div>}
       {activeItem === 'Accounts' && <div>
       <Accounts  />
       </div>}
       
       {activeItem === 'API' && <div>
       <API  />
       </div>}
       {activeItem === 'Config' && <div>
       <Config  functionOptions={functionOptions} setFunctionOptions={setFunctionOptions}  />
       </div>}
       </div>
       <RightPanel functionOptions={functionOptions} talknojutsu={talknojutsu} templateData={initialProjects} panelIsVisible={panelIsVisible} setPanelIsVisible={setPanelIsVisible} handleLogout={handleLogout}/>
       </div>
      ):("")    
      }
      <Router>
        <Routes>
        <Route path="/explainer" element={<div><h1>Explainer...</h1></div>} />
          {isLoggedIn ? (""
          ) : (
            <Route path="/" element={<Login onLogin={handleLogin} />} />
          )}
        </Routes>
      </Router>
    </div></GoogleOAuthProvider>
  );
}

export default App;
