import React, { Component } from 'react';
import axios from 'axios';

class Swagger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: 'Create a reactjs app',
      messages: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { query } = this.state;

    if (query.trim() === '') {
      return;
    }

    this.setState({ isLoading: true });

    axios
      .get(`https://worker-sweet-shadow-6fe7.radisys.workers.dev/search?q=${query}`)
      .then((response) => {
        const newMessage = {
          text: response.data[0], // Assuming the API returns an array with a single result
          isUser: false, // Indicates if it's a user or bot message
        };
console.log( response.data[0]);
        this.setState((prevState) => ({
          messages: [...prevState.messages, newMessage],
          isLoading: false,
        }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ isLoading: false });
      });
  }

  handleSearch = () => {
    const { query } = this.state;

    if (query.trim() === '') {
      return;
    }

    const userMessage = {
      text: query,
      isUser: true,
    };

    this.setState(
      (prevState) => ({
        messages: [...prevState.messages, userMessage],
        query: '',
      }),
      () => this.fetchData()
    );
  };

  render() {
    const { query, messages, isLoading } = this.state;

    return (
      <div>
        <h1>Frontend Agent</h1>
        <div className="chat-container">
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${message.isUser ? 'user' : 'bot'}`}
              >
                {message.text}
              </div>
            ))}
            {isLoading && <p>Loading...</p>}
          </div>
          <div className="chat-input">
            <input
              type="text"
              placeholder="Type your message..."
              value={query}
              onChange={(e) => this.setState({ query: e.target.value })}
            />
            <button onClick={this.handleSearch}>Send</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Swagger;
