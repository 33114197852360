import React from 'react';
import { IconContext } from 'react-icons';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import Activities from '../../components/activities/Activities';
import * as Icons from 'react-icons/fa';
import './dashboard.css';

const Dashboard = () => {
  // Fetch component data from an API or set it manually
  const cloud = [{"Akash":"","AWS":"FaAws","Cloudflare":"FaCloudflare"}];

  
  const components = [
   
  ];
  

  const IComponent = ({ value }) => {

    const IconComponent = Icons[value] || ChainIcons[value];

    return (
      <div>
        <IconContext.Provider value={{ size: '1.5em' }}>
          {IconComponent && <IconComponent />}
        </IconContext.Provider>
      </div>
    );
  };

  return (
    <div className="dashboard">
 <div className="component-list">
  {components.map((component) => (
    <div key={component.id} className={`component-card ${component.provider}`}>
           <div className="uptime-bar" style={{ width: `${component.uptime}%`, backgroundColor: (component.uptime >= 90 ? 'green' : 'red') }}>
      </div>
    
      <h3 className={component.status}>{component.name}</h3>

      <div className="provider">
        <IComponent value={component.icon} />
        {component.provider}
      </div>
  
    </div>
  
  ))}
</div>


<Activities />
    </div>
  );
};

export default Dashboard;
