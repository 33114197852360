import React from 'react';
import './Accounts.css';
import { IconContext } from 'react-icons';
import * as ChainIcons from "@thirdweb-dev/chain-icons";

import networkdata from '../../init/networks.json';
import accountsi from '../../init/accounts.json';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      searchQuery: '',
    };
  }

  componentDidMount() {
    // Fetch account data from an API or set it manually
    const accounts = accountsi;

    this.setState({ accounts });
  }

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  render() {
    var getIconByName= (name)=>{
      const iconData = networkdata;
      const item = iconData.find((item) => item.label.toLowerCase() === name.toLowerCase());
      return item ? item.icon : null;
    }
    const IComponent = ({ value }) => {
      const IconComponent = ChainIcons[value] || (() => <img src={`/images/${value}.svg`} height="25" />);
    
  

      return (
        <div>
          <IconContext.Provider >{IconComponent && <IconComponent height={25} />}</IconContext.Provider>
        </div>
      );
    };

    
    const { accounts, searchQuery } = this.state;

    // Filter accounts based on the search query
    const filteredAccounts = accounts.filter((account) => {
      const { id, name, chain, contractAddress, userIdRef, template } = account;
      const query = searchQuery.toLowerCase();

      return (
        id.toString().includes(query) ||
        name.toLowerCase().includes(query) ||
        chain.toLowerCase().includes(query) ||
        contractAddress.toLowerCase().includes(query) ||
        userIdRef.toLowerCase().includes(query) ||
        template.toLowerCase().includes(query)
      );
    });

    return (
      <div className="account-component">
        <h2>User Accounts</h2>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={this.handleSearchChange}
        />
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Chain</th>
              <th>Contract Address</th>
              <th>User ID Ref</th>
              <th>Template</th>
            </tr>
          </thead>
          <tbody>
            {filteredAccounts.map((account) => (
              <tr key={account.id}>
                <td>{account.id}</td>
                <td>{account.name}</td>
                <td>  <IComponent value={getIconByName(account.chain)} /> {account.chain}</td>
                <td>{account.contractAddress}</td>
                <td>{account.userIdRef}</td>
                <td>{account.template}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Account;
