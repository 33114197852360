import React, { useState, useEffect, useRef } from 'react';
import './ProjectList.css';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import * as Icons from 'react-icons/fa';
import { IconContext } from 'react-icons';

import data from '../../init/project.json';
import networkdata from '../../init/networks.json';

const ProjectList = ({ projects, setProjects, setActiveItem }) => {

  const iconData = networkdata;
  const [visibleProjects, setVisibleProjects] = useState([projects[0], projects[1], projects[2]]);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const containerRef = useRef(null);
  const itemHeight = 200; // Assuming each project item has a height of 200px
  const itemsPerPage = 3; // Number of visible projects per page
  const [direction, setDirection] = useState(null);
  const touchStartRef = useRef(null);

  const getModifiedArray = (initialPosition) => {
    var arr = [];
    for (var i = 0; i < projects.length; i++) {
      arr.push(i);
    }
    const modifiedArr = [...arr];
    const position = (initialPosition - 1) % modifiedArr.length;
    return [...modifiedArr.slice(position), ...modifiedArr.slice(0, position)];
  };

  const scrollUp = () => {
    // Scrolling up
    setDirection("up");
    var vpos = [];
    var currentScrollPos = prevScrollPos - 1;
    if (currentScrollPos < 0 || prevScrollPos > 3) {
      var sorted = getModifiedArray(currentScrollPos);
      currentScrollPos = sorted[0];

      vpos = [sorted[0], sorted[1], sorted[2]];
    } else {
      vpos = [currentScrollPos, currentScrollPos + 1, currentScrollPos + 2];
    }
    setPrevScrollPos(currentScrollPos);
    setVisibleProjects([
      projects[vpos[0]],
      projects[vpos[1]],
      projects[vpos[2]],
    ]);
  };

  const scrollDown = () => {
    var currentScrollPos = prevScrollPos + 1;
    // Scrolling down

    var vpos = [];
    setDirection("down");
    if (currentScrollPos > projects.length - 3) {
      var sorted = getModifiedArray(currentScrollPos + 1);
      currentScrollPos = sorted[0];

      vpos = [sorted[0], sorted[1], sorted[2]];
    } else {
      vpos = [currentScrollPos, currentScrollPos + 1, currentScrollPos + 2];
    }
    setPrevScrollPos(currentScrollPos);
    setVisibleProjects([
      projects[vpos[0]],
      projects[vpos[1]],
      projects[vpos[2]],
    ]);
  };

  useEffect(() => {
    const container = containerRef.current;

    const handleWheel = (event) => {
      var currentScrollPos = event.deltaY;
      var delta = currentScrollPos - prevScrollPos;
      var vpos = [];
      if (delta < 0) {
        scrollUp();
      } else if (delta > 0) {
        scrollDown();
      }
    };

    container.addEventListener('wheel', handleWheel);

    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, [prevScrollPos, projects]);

  useEffect(() => {
    const container = containerRef.current;

    const handleVisibleProjectsTransition = () => {
      const visibleProjectElements = container.getElementsByClassName('project');

      if (direction === "up") {
        for (var i = 0; i < visibleProjectElements.length; i++) {
          visibleProjectElements[i].classList.remove('fade-down');
          visibleProjectElements[i].classList.add('fade-up');
        }
      } else {
        for (var i = 0; i < visibleProjectElements.length; i++) {
          visibleProjectElements[i].classList.remove('fade-up');
          visibleProjectElements[i].classList.add('fade-down');
        }
      }
    };

    handleVisibleProjectsTransition();
  }, [visibleProjects, direction]);

  const handleDeleteProject = (id) => {
    const updatedProjects = projects.filter((project) => project.id !== id);
    setProjects(updatedProjects);
    setPrevScrollPos(0);
    if (projects.length > 2) {
      setVisibleProjects([
        projects[0],
        projects[1],
        projects[2],
      ]);
    } else {
      setVisibleProjects(projects);
    }

  };

  const handleSelectProject = (project) => {
    // ...
  };

  const getIconByName = (name) => {
    const item = iconData.find((item) => item.id.toLowerCase() === name.toLowerCase());
    return item ? item.icon : null;
  };

  const IComponent = ({ value }) => {
    const IconComponent = ChainIcons[value] || (() => <img src={`/images/${value}.svg`} height="25" />);

    return (
      <div>
        <IconContext.Provider >{IconComponent && <IconComponent height={25} />}</IconContext.Provider>
      </div>
    );
  };

  const handleTouchStart = (event) => {
    touchStartRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    const touchEnd = event.touches[0].clientY;
    const touchStart = touchStartRef.current;
    const delta = touchEnd - touchStart;

    if (delta < -50) {
      scrollDown();
    } else if (delta > 50) {
      scrollUp();
    }
  };

  return (
    <div
      className="project-list-container"
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="project-list">
        {visibleProjects.map((project) => (
          <div className='project' key={project.id}>
            <div className="project-info">
              <h3>{project.templateName}</h3>
              <div className='templatedetails'>
                <p><b>Auth Methods:</b> {project.authMethods.map((method) => method).join(', ')}</p>
                <p><b>Functions:</b> {project.functions.map((func) => func).join(', ')}</p>
              </div>
              <p>
                <div className="networks">
                  {project.networks.map((network) => (
                    <IComponent value={getIconByName(network)} />
                  ))}
                  <br /><sup><b>Supported Networks</b></sup>
                </div>
              </p>
              <br></br>
              <div className='templatedetails'>
                <Icons.FaGithub></Icons.FaGithub>
                <a href={project.githubRepo} target="_blank" rel="noopener noreferrer">
                  {project.githubRepo}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="directions">
        <button onClick={scrollUp}><Icons.FaCaretUp /></button><br />
        <button onClick={scrollDown}><Icons.FaCaretDown /></button>
      </div>
    </div>
  );
};

export default ProjectList;
