import React, { useState } from 'react';
import APIM from '../../components/apimanager/APIM';
import Swagger from '../../components/apimanager/Swagger';

import './api.css'; 

const API = ({contacts, setContacts}) => {

  const [name, setName] = useState('');
  const [chain, setChain] = useState('');
  const [address, setAddress] = useState('');
  const [editingContact, setEditingContact] = useState(null);
  const supportedChains = ['Ethereum', 'Algorand', 'Solana', 'Stellar', 'Avalanche', 'Hedera'];

  const handleAddContact = () => {
    if (name && chain && address) {
      const newContact = { name, chain, address };
      setContacts([...contacts, newContact]);
      setName('');
      setChain('');
      setAddress('');
    } else {
      console.error('Please fill in all the required fields.');
    }
  };

  const handleEditContact = (contact) => {
    setEditingContact(contact);
    setName(contact.name);
    setChain(contact.chain);
    setAddress(contact.address);
  };

  const handleUpdateContact = () => {
    if (name && chain && address && editingContact) {
      const updatedContacts = contacts.map((contact) =>
        contact === editingContact ? { ...contact, name, chain, address } : contact
      );
      setContacts(updatedContacts);
      setEditingContact(null);
      setName('');
      setChain('');
      setAddress('');
    } else {
      console.error('Please fill in all the required fields.');
    }
  };

  const handleDeleteContact = (index) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(index, 1);
    setContacts(updatedContacts);
  };

  return (
    <div className="api">
<APIM/>
<Swagger/>
    </div>
  );
};

export default API;
