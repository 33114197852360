import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { IconContext } from 'react-icons';
import SVG from 'react-inlinesvg';
import * as ChainIcons from "@thirdweb-dev/chain-icons";
import networks from "../../init/networks.json";

import './FunctionEditor.css';

const FunctionEditor = ({ functionOptions, setFunctionOptions }) => {
  const [selectedFunction, setSelectedFunction] = useState('');
  const [selectedChain, setSelectedChain] = useState('');
  const [codeSnippet, setCodeSnippet] = useState('');
  const [selectGroup, setSelectGroup] = useState([]);
  const [functionBlocks, setFunctionBlocks] = useState({});
  const [customFunctions, setCustomFunctions] = useState([]);
  const [customFunctionName, setCustomFunctionName] = useState('');

  const IComponent = ({ value }) => {
    const IconComponent = ChainIcons[value] || (() => <img src={`/images/${value}.svg`} height="25" />);

    return (
      <IconContext.Provider>{IconComponent && <IconComponent height={25} />}</IconContext.Provider>
    );
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <div style={{ padding: '5px', cursor: 'pointer' }}>
        <IComponent value={data.icon} />
        <span style={{ verticalAlign: 'top', lineHeight: '25px', padding: '0px 6px' }}>{label}</span>
      </div>
    </div>
  );

  const CustomSingleValue = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <IComponent value={data.icon} />
      <span style={{ verticalAlign: 'top', lineHeight: '25px', padding: '0px 6px' }}>{data.label}</span>
    </div>
  );

  const handleFunctionSelection = (selectedOption) => {
    setSelectedFunction(selectedOption);

    if (selectedOption.value === 'custom') {
      setCodeSnippet('');
      setCustomFunctionName('');
      selectedOption.label = '';
      setSelectedFunction(selectedOption);
    } else {
      const selectedBlock = functionBlocks[selectedOption.value]?.[selectedChain?.value];
      if (selectedBlock) {
        setCodeSnippet(selectedBlock);
      } else {
        setCodeSnippet('');
      }
    }
  };

  const handleChainSelection = (selectedOption) => {
    setSelectedChain(selectedOption);
    if (selectedFunction?.value !== 'custom') {
      const selectedBlock = functionBlocks[selectedFunction?.value]?.[selectedOption?.id];
      if (selectedBlock) {
        setCodeSnippet(selectedBlock);
      } else {
        setCodeSnippet('');
      }
    }
  };

  const handleCodeSnippetChange = (event) => {
    setCodeSnippet(event.target.value);
  };

  const handleUpdateFunctionBlock = () => {
    if (selectedFunction && selectedChain && codeSnippet) {
      setFunctionBlocks((prevFunctionBlocks) => {
        const newFunctionBlocks = { ...prevFunctionBlocks };

        if (selectedFunction.value === 'custom') {
          const newCustomFunction = {
            value: customFunctionName,
            label: customFunctionName,
          };
  
          newFunctionBlocks[customFunctionName] = {
            ...(newFunctionBlocks[customFunctionName] || {}),
            [selectedChain.id]: codeSnippet,
          };
  
          setCustomFunctions((prevCustomFunctions) => [...prevCustomFunctions, newCustomFunction]);
          setFunctionOptions([...functionOptions, newCustomFunction]);
        } else {
          newFunctionBlocks[selectedFunction.value] = {
            ...(newFunctionBlocks[selectedFunction.value] || {}),
            [selectedChain.id]: codeSnippet,
          };
        }
  
        return newFunctionBlocks;
      });
      setSelectedFunction('');
      setSelectedChain('');
      setCodeSnippet('');
    }

  };
  

  const networkOptions = networks;

  useEffect(() => {
    setSelectGroup([...functionOptions, { value: 'custom', label: 'Custom' }]);
  }, [functionOptions, customFunctions]);

  const networkSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '5px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#003a80' : 'transparent',
      color: state.isSelected ? '#fff' : '#333',
      cursor: 'pointer',
    }),

    input: () => ({
      display: 'none',
    }),
  };

  return (
    <div className="configcom function-editor">
      <div class="header">Function Editor</div>
      <div className="input-container">
        <label>Select Function:</label>
        <Select
          options={selectGroup}
          value={selectedFunction}
          onChange={handleFunctionSelection}
          isSearchable={false}
        />
      </div>
      {selectedFunction?.value === 'custom' && (
        <div className="input-container">
          <label>Custom Function Name:</label>
          <input
            type="text"
            placeholder="Enter function name"
            value={customFunctionName}
            onChange={(event) => setCustomFunctionName(event.target.value)}
          />
        </div>
      )}
      <div className="input-container">
        <label>Select Chain:</label>
        <Select
          options={networkOptions}
          value={selectedChain}
          onChange={handleChainSelection}
          components={{
            SingleValue: CustomSingleValue,
            Option: CustomOption,
          }}
          styles={networkSelectStyles}
        />
      </div>
      <div className="input-container">
        <label>Code Snippet:</label>
        <textarea
          rows="5"
          placeholder="Enter code snippet"
          value={codeSnippet}
          onChange={handleCodeSnippetChange}
        ></textarea>
      </div>
      
        <button onClick={handleUpdateFunctionBlock}>Update</button>
      
    </div>
  );
};

export default FunctionEditor;
