import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as ReactIconmd from 'react-icons/md';
import * as ReactIcon from 'react-icons/gr';
import { GoogleLogin } from '@react-oauth/google';

import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform login authentication logic here
    // Replace the following condition with your authentication logic
    if(username=="123" && password=="abc"){
    onLogin(true);
    }
  };

  return (
    <div className='backdrop'>
         <video className="videobg" autoPlay loop muted src="https://adappter.xyz/videos/background.mp4" type='video/mp4' />
    <div className="login-container" style={{background:"#FFF"}}>
    <h1>RAMENINC XYZ</h1>
<div className='bg'  >
<div style={{background:"#FFF",height:"calc(100% - 4px)" }}></div>

</div>
<div className='bg'>

    <div style={{background:"#FFF",padding:"20px","position":"relative","top":"0px"}}>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <br/>
          <div htmlFor="username">Username:</div>
          <input type="text" id="username" value={username} onChange={handleUsernameChange} />
        </div>
        <div className="form-group">
          <div htmlFor="password">Password:</div>
          <input type="password" id="password" value={password} onChange={handlePasswordChange} />
        </div>
        <div className="form-group" style={{"text-align":"right"}}>
        <button><ReactIconmd.MdLogin />  Login</button>
        </div>
        <hr></hr>
        <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
    onLogin(true);
    localStorage.session=credentialResponse.credential;
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>
      </form>
      </div>
      </div>
      </div>
    </div>
  );
};

export default Login;
